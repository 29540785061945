<template>
  <div style="min-width: 1900px; overflow: hidden">
    <div
      class="introduction_about scaleBanner"
      :style="'background-image: url(' + domList[DomId].url + ');'"
    >
      <div class="introduction_about_box">
        <div class="introduction_about_name moveTopTitle">
          {{ domList[DomId].val }}
        </div>
      </div>
      <div class="box"></div>
    </div>
    <div
      style="margin: 0 auto; padding-top: 180px"
      :style="
        childcityList.length == 0
          ? 'background: url(' +
            require(`../../../static/v1.0/青山.png`) +
            ') center bottom / cover no-repeat;'
          : ''
      "
    >
      <div class="specific moveTopNormal">
        <div class="specific_dom">
          <div
            style="
              max-width: 1580px;
              display: flex;
              justify-content: space-between;
              margin: 0 auto;
              position: relative;
            "
          >
            <div class="font_left">
              <!-- style="padding-top: 75px" -->
              <div class="title" style="max-width: 700px">
                <!-- {{ companyItem.companyName }} -->
              </div>
              <div class="font_">
                能源版块是当今社会发展中的一个关键领域，其发展与利用对环境、经济和社会都有着深远的影响
              </div>
              <div class="font_top">
                <div @click.stop="jumpArea" style="cursor: pointer">
                  <div class="number">{{ statisticInfo.plantedNumber }}</div>
                  <div class="numberTpye">覆盖面积(亩)</div>
                </div>
                <div>
                  <div class="number">{{ statisticInfo.projectPersonnel }}</div>
                  <div class="numberTpye">工作人员(人)</div>
                </div>
              </div>
              <div class="font_bottom">
                <div>
                  <div class="number">{{ statisticInfo.subordinateUnits }}</div>
                  <div class="numberTpye">下属单位(家)</div>
                </div>
                <div @click.stop="jumpArea" style="cursor: pointer">
                  <div class="number">
                    {{ statisticInfo.projectBaseNumber }}
                  </div>
                  <div class="numberTpye">基地数量(个)</div>
                </div>
              </div>
            </div>

            <!-- 全国网点 -->
            <div class="moveTopNormal allBranch">
              <dot-map1
                :openDom="false"
                :adminCode="100000"
                :PropsCitylist="this.childcityList"
                :fatherCityList="this.companyItem"
              ></dot-map1>
            </div>
            <div
              class="moveTopNormal allBranch"
              style="z-index: 1; height: 880px"
            ></div>
          </div>
        </div>
        <div class="specific_child" v-if="childcityList.length">
          <div class="specific_child_info_project">
            <div
              style="
                max-width: 1580px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 100px;
              "
            >
              <div
                class="specific_child_info_project_item moveTopNormal"
                v-for="item in childcityList"
                :key="item.projectId"
                @click="jumpcityClick(item)"
              >
                <div class="specific_child_info_project_item_img">
                  <img :src="item.companyImage" style="width: 100%;" />
                  <div
                    class="specific_child_title"
                    :class="{ big_img: item.companyId == 0 }"
                  >
                    <div
                      style="
                        bottom: 33px;
                        left: 28px;
                        font-family: 楷体;
                        position: absolute;
                        font-weight: 900;
                        font-size: 44px;
                        color: #ffffff;
                        line-height: 42px;
                        text-align: center;
                      "
                    >
                      {{ item.companyName }}
                    </div>
                  </div>
                </div>
                <div class="specific_child_info_project_item_tips">
                  <div
                    class="specific_child_info_project_item_tips_left"
                    style="font-size: 24px; line-height: 30px"
                  >
                    <div class="specific_child_info_project_item_tips_right">
                      {{ item.companyDesc }}
                    </div>
                  </div>
                  <div
                    v-if="item.companyId != 0"
                    class="specific_child_info_project_item_tips_more"
                  >
                    了解详情<img
                      src="../../../static/v1.0/21.png"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div class="specific_child_info_project_item_tips_line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="middle moveTopNormal"
      v-if="middle_journalism && middle_journalism.length"
    >
      <div
        class="imgbox"
        :style="
          'background: url(' +
            require('../../../static/v1.0/版块bg.png') +
            ');background-repeat: no-repeat;background-size: cover;background-position:bottom;'
        "
      >
        <div
          class="titile"
          style="
            height: 420px;
            background-image: linear-gradient(180deg, #fff, #f9f9f9);
          "
        >
          <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
          <img class="twoI" src="../../../static/v1.0/two.png" alt="" />
          <img class="img" src="../../../static/v1.0/新·闻·中·心.png" alt="" />
          <span class="imgT">NEWS CENTER</span>
        </div>
        <div style="max-width: 1900px; margin: 0 auto; padding-bottom: 40px">
          <div class="middle_new">
            <div class="middle_Left">
              <el-carousel
                :interval="5000"
                ref="middleCarousel"
                arrow="never"
                @change="carouselMid"
              >
                <!-- @click="toDetails(item, index)" -->
                <el-carousel-item
                  :style="
                    `background-image: url(${
                      item.newsCover
                    });background-size: cover;`
                  "
                  v-for="(item, index) in middle_journalism"
                  :key="index"
                >
                  <div
                    style="min-width: 896px; height: 512.4px"
                    @click="toDetails(item, index)"
                  ></div>
                  <div class="mask" @click="toDetails(item, index)"></div>
                  <div class="middle_item" @click="toDetails(item, index)">
                    <div class="middle_font">
                      {{ item.newsTitle }}
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="cornerMarkBoxMid">
                <div
                  :class="{ ckcss: midCk == item }"
                  class="cornerMarkMid"
                  v-for="item in 3"
                  :key="item"
                  @click="cornerMid(item)"
                >
                  <div style="color: #fff">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="middle_Rigth">
              <!-- style="color:#157b2f;" -->
              <div
                v-for="(item, index) in middle_journalism"
                @click="toDetails(item, index)"
                :key="index"
              >
                <div
                  class="middle_Ritem moveTop1"
                  @mouseover="mouseOverNews(index)"
                >
                  <div class="middle_Rfont">{{ item.newsTitle }}</div>
                  <div
                    style="
                      display: flex;
                      line-height: 16px;
                      margin-bottom: 46px;
                    "
                  >
                    <div class="minddle_time">
                      {{ item.publishTime }}
                    </div>
                    <div style="margin: 0 20px 0 29px">|</div>
                    <div class="minddle_danwie">
                      {{ item.newsIntroduction || item.newsTitle }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="middle_three">
            <el-carousel
              indicator-position="none"
              :autoplay="false"
              arrow="never"
              height="600px"
              ref="newsTypeCarousel"
            >
              <el-carousel-item v-for="num in 3" :key="num">
                <div class="swiper-container swiper-container3">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide home_module_8_module"
                      v-for="(arr, index) in middle_journalism_three"
                      :key="index"
                    >
                      <div
                        class="home_module_8_module_item"
                        v-for="(item, index2) in arr"
                        :key="index2"
                        @mouseout="tomouseout"
                        @mousemove="tomousemove(index2)"
                        @click="toDetails(item)"
                        v-if="item"
                      >
                        <div class="home_module_8_cover">
                          <img :src="item.newsCover" />
                        </div>
                        <div class="home_module_8_date">
                          <div>{{ item.publishTime }}</div>
                        </div>
                        <div class="home_module_8_title">
                          <div>{{ item.newsTitle }}</div>
                        </div>
                        <div class="home_module_8_more1">
                          <img
                            v-show="mousemoveIndex != index2"
                            src="../../../static/v1.0/L_b.png"
                            alt=""
                            srcset=""
                          />
                          <div v-show="mousemoveIndex == index2">
                            <img :src="introduction_bg1" class="one" alt="" />
                            <img
                              :src="introduction_bg1"
                              class="two"
                              style="margin: 0 3px"
                              alt=""
                            />
                            <img :src="introduction_bg1" class="three" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="home_module_8_content_right">
                  <div class="home_module_8_content_dom">
                    <div>
                      <i
                        class="el-icon-arrow-left icon-left"
                        :class="newsTypeIndex == 0 ? 'nodata' : ' moveTop'"
                        @click="changeNewsTypeIndex(-1)"
                      ></i>
                      <i
                        class="el-icon-arrow-right icon-right"
                        :class="newsTypeIndex == 2 ? 'nodata' : ' moveTop'"
                        @click="changeNewsTypeIndex(1)"
                      ></i>
                    </div>
                    <!-- class="home_module_8_content_right_more moveTop" -->
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="base moveTopNormal">
      <div class="base_bul">
        <div style="max-width: 1900px; margin: 0 auto">
          <div class="titile">
            <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
            <img
              class="img"
              src="../../../static/v1.0/基·地·中·心.png"
              alt=""
            />
            <span class="imgT">Base center</span>
          </div>
          <div class="base_child">
            <div
              class="base_child_info_project"
              v-if="childProjectList.length > 0"
            >
              <div
                class="base_child_info_project_item moveTopNormal"
                v-for="(item, index) in childProjectList"
                :key="item.projectId + 'childProjectList' + index"
                @click="jumpClick(item)"
              >
                <div class="base_child_info_project_item_img">
                  <img :src="item.projectImage" width="100%" />
                  <div class="base_child_title" style="">
                    <div
                      style="
                        bottom: 31px;
                        left: 16px;
                        font-family: 楷体;
                        position: absolute;
                      "
                    >
                      {{ item.projectName }}
                    </div>
                  </div>
                </div>
                <div
                  class="base_child_info_project_item_tips"
                  v-if="item.projectId != -1"
                >
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-weight: bold"
                  ></div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 10px;
                      margin-bottom: 12px;
                    "
                  >
                    项目时间：{{ item.projectDate.split(",")[0] }} 至
                    {{ item.projectDate.split(",")[1] }}
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 12px;
                      margin-bottom: 12px;
                    "
                  >
                    所属单位：浙江中峪道地药材有限公司
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-size: 20px; line-height: 30px"
                  >
                    <div class="base_child_info_project_item_tips_right">
                      {{ item.projectDesc }}
                    </div>
                  </div>
                  <div class="base_child_info_project_item_tips_more">
                    了解详情<img
                      src="../../../static/v1.0/21.png"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div class="base_child_info_project_item_tips_line"></div>
                </div>
              </div>
            </div>

            <div
              class="base_child_info_project"
              v-if="childProjectList.length == 0"
              style="text-align: center;display: block;border-top: 1px solid #f0f0f0;margin-top: 40px"
            >
              <img
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-19/dce89a55ac694fecba87dbe7f715a5af.png"
                width="700px"
                style="margin-top: 20px;"
              />
              <div
                style="width: 100%;font-weight: bold;font-size: 26px;color: #c9c9c9;line-height: 39px;"
              >
                更多基地正在开发中···
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="font moveTopNormal">
      <div class="font_dom">战略合作伙伴：</div>
      <div style="display: flex">
        <div class="font_div">
          <div
            class="font_for"
            v-for="(item, index) in companyName"
            :key="index"
          >
            <div style="line-height: 122px">
              <img :src="item.url" width="30px" />
            </div>
            <div style="margin: 0 60px 0 10px">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay, Mousewheel } from "swiper";
import "swiper/swiper-bundle.css";
import dotMap1 from "../map1.vue";
import {
  getCompanyList,
  getNewsList,
  getProject,
  getStatistic,
  getSignature,
  getCompanyDetailById,
} from "@/api/index.js";
Swiper.use([Navigation, Pagination, Autoplay, Mousewheel]);
export default {
  name: "home",
  components: { dotMap1 },
  data() {
    return {
      companyName: [
        {
          name: "国家电力投资集团",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/331a9ccf00f142e0b92d9afcee41f190.png",
        },
        {
          name: "运达能源科技集团股份有限公司",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/cc6ab34dac7240f1800f026e30468113.png",
        },
      ],
      mousemoveIndex: null,
      mousemoveType: null,
      //热点新闻
      head_journalism: [
        {
          time: "2024-04-05",
          data:
            "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-15",
          data:
            "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-25",
          data:
            "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-35",
          data:
            "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
      ],
      //角标选中
      cssCk: 1,
      midCk: 1,
      DomId: 0,
      domList: [
        {
          id: 0,
          val: "能源版块",
          url: require("../../../static/v1.0/大风车.png"),
        },
      ],
      //新闻中心
      middle_journalism: [],
      middle_journalism_three: [],
      newsTypeIndex: 0,
      container3: null,
      newsType: 0,
      imgIndex: 0,
      imgIndexF: 0,
      bannerIndex: 0,
      bannerTxt: "手写的处方",
      banner: [
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
      ],
      indexC: null,
      indexC2: null,
      baseList: [
        {
          name: "种植基地",
          id: 1,
        },
        {
          name: "种子基地",
          id: 0,
        },
        {
          name: "种苗基地",
          id: 2,
        },
        {
          name: "科研基地",
          id: 3,
        },
      ],
      introduction_bg: require("../../../static/v1.0/3.png"),
      introduction_bg1: require("../../../static/v1.0/lv_3.png"),
      childProjectList: [],
      childcityList: [],
      onbase: 1,
      companyItem: {},
      statisticInfo: {},
    };
  },

  mounted() {
    let company;
    if (this.$route.query.companyId) {
      getCompanyDetailById(this.$route.query.companyId).then((res) => {
        company = res.data;
        this.$store.commit("setCompany", company);
        this.companyItem = company;
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.getChildCompany();
        this.getList();
        this.getChildProjectList();
        this.getStatistic();
        this.getWXInfo();
      });
    } else {
      company = this.$store.getters.getCompany;
      this.companyItem = company;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.getChildCompany();
      this.getList();
      this.getChildProjectList();
      this.getStatistic();
      this.getWXInfo();
    }
  },

  methods: {
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.companyItem.companyName + "能源版块", // 分享标题
            desc:
              "能源版块是当今社会发展中的一个关键领域，其发展与利用对环境、经济和社会都有着深远的影响", // 分享描述
            link:
              window.location.href +
              "?companyId=" +
              that.companyItem.companyId +
              "&companyArr=" +
              JSON.stringify(that.$store.getters.getCompanyTier), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-10-09/2cbd737cd2464088bfc4d0d8d300cd9e.png", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    getStatistic() {
      getStatistic(this.companyItem.companyId).then((res) => {
        const data = res.data;
        this.statisticInfo = {
          plantedNumber: data[1].plantedNumber + data[2].plantedNumber,
          projectBaseNumber:
            data[1].projectBaseNumber + data[2].projectBaseNumber,
          projectPersonnel: data[1].projectPersonnel + data[2].projectPersonnel,
          subordinateUnits: data[1].subordinateUnits + data[2].subordinateUnits,
        };
      });
    },
    getChildProjectList() {
      getProject({
        companyId: this.companyItem.companyId,
        projectType: 4,
        withChildCompany: 1,
        current: 1,
        size: 4,
      }).then((res) => {
        this.childProjectList = res.data.list;
        this.$nextTick(() => {
          const arr = document.querySelectorAll(
            ".base_child_info_project_item"
          );
          arr.forEach((val) => {
            val.className = "base_child_info_project_item moveTopNormal";
            setTimeout(() => {
              val.className =
                "base_child_info_project_item moveTopNormal moveTopAnimation";
            }, 500);
          });
        });
      });
    },
    getChildCompany() {
      this.childcityList = [];
      getCompanyList(
        {
          pid: this.companyItem.companyId,
          current: 1,
          size: 2,
        },
        { companyCategories: [1, 2] }
      ).then((res) => {
        this.childcityList = res.data.list;
      });
    },
    mouseOverNews(index) {
      this.midCk = index + 1;
      this.$refs.middleCarousel.setActiveItem(index);
    },
    jumpArea() {
      this.$router.push({
        path: `/medicinal?id=4`,
      });
    },
    jumpClick(item) {
      if (item.projectId != -1) {
        this.$router.push({
          path: `/projectDetail?id=` + item.projectId,
        });
      }
    },
    jumpcityClick(item) {
      if (item.companyId != 0) {
        this.$store.commit("setCompany", item);
        if (item.companyLevel == 2) {
          this.$router.push({
            path: `/ProvincialCompany`,
          });
        } else if (item.companyLevel == 3) {
          this.$router.push({
            path: `/cityCompany`,
          });
        } else {
          this.$router.push({
            path: `/areaCompany`,
          });
        }
      }
    },
    onbaseAll(index) {
      this.onbase = index;
    },
    tomouseout() {
      this.mousemoveIndex = null;
    },
    tomousemove(index) {
      this.mousemoveType = true;
      this.mousemoveIndex = index;
    },
    toDetails(item) {
      this.$router.push({
        path: `/newsListDetail?id=${item.newsId}&type=能源版块&index=0`,
      });
    },
    getList() {
      getNewsList(
        {
          current: 1,
          size: 12,
          companyId: this.companyItem.companyId,
          withChildCompany: 2,
        },
        { companyCategoryList: [1, 2] }
      ).then((res) => {
        if (res.data.list && res.data.list.length > 0) {
          this.newsList = res.data.list;
          this.middle_journalism = res.data.list;
          this.middle_journalism = res.data.list.slice(0, 3);
          if (res.data.total > 9) {
            this.newAllList = [
              [this.newsList[3], this.newsList[4], this.newsList[5]],
              [this.newsList[6], this.newsList[7], this.newsList[8]],
              [this.newsList[9], this.newsList[10], this.newsList[11]],
            ];
          }
          if (res.data.total > 9) {
            this.middle_journalism_three = [
              [this.newsList[3], this.newsList[4], this.newsList[5]],
              [this.newsList[6], this.newsList[7], this.newsList[8]],
              [this.newsList[9], this.newsList[10], this.newsList[11]],
            ];
          }
        }
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.container3 = new Swiper(".swiper-container3", {
            slidesPerView: 1,
            observer: true, //开启动态检查器，监测swiper和slide
            observeParents: true, //监测Swiper 的祖/父元素
            loop: false,
          });
        }, 500);
        let that = this;
        let mySwiper = new Swiper(".swiper-container1", {
          direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
          loop: true, // 设置为true 则开启loop模式
          autoplay: 1000, // 自动切换时间
          slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
          centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
          spaceBetween: 90, // 在slide之间设置距离（单位px）。
          loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
          slideToClickedSlide: true,
        });
        let mySwiper2 = new Swiper(".swiper-container2", {
          direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
          loop: true, // 设置为true 则开启loop模式
          autoplay: 1000, // 自动切换时间
          slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
          centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
          spaceBetween: 40, // 在slide之间设置距离（单位px）。
          //   loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
          slideToClickedSlide: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    },
    cornerCM(item) {
      this.cssCk = item;
      this.$refs.headCarousel.setActiveItem(item - 1);
    },
    cornerMid(item) {
      this.midCk = item;
      this.$refs.middleCarousel.setActiveItem(item - 1);
    },
    carouselC(e) {
      this.cssCk = e + 1;
    },
    carouselMid(e) {
      this.midCk = e + 1;
    },

    changeNewsTypeIndex(index) {
      if (this.newsTypeIndex == 2 && index == 1) {
        return;
      } else if (this.newsTypeIndex == 0 && index == -1) {
        return;
      }
      this.newsTypeIndex += index;
      console.log(this.container3, this.newsType);
      if (index > 0) {
        this.container3[this.newsType].slideNext();
      } else {
        this.container3[this.newsType].slidePrev();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 940px;
  padding-top: 175px;
  background-repeat: no-repeat;
  background-size: cover;

  .head_minBg {
    width: 100%;
    min-width: 1900px;
    height: 1115px;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(23px);
    position: absolute;
    top: 0;
    left: 0;
  }

  .headTv {
    width: 1451px;
    height: 848px;
    position: relative;
    margin: 0 auto;
    background: url("../../../static/v1.0/frame.png");
  }

  .hotImg {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }

  .headBox {
    padding-top: 21px;
    overflow: hidden;
    width: 1400px;
    height: 800px;
    margin: 0 auto;
    display: flex;
  }

  .head_img {
    min-width: 1400px;
    height: 800px;
    margin: 0 auto;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.13) 0%,
      rgba(0, 0, 0, 0.83) 100%
    );

    .head_bg {
      .mask {
        width: 1400px;
        height: 400px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }
    }

    .head_item {
      position: absolute;
      bottom: 63px;
      left: 64px;

      .head_font {
        width: 1000px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // word-break: break-all;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // height: 104px;
        font-weight: bold;
        font-size: 32px;
        color: #ffffff;
        line-height: 52px;
        letter-spacing: 1px;
        text-align: justify;
        font-style: normal;
      }

      .head_font1 {
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        opacity: 0.9;
      }
    }
  }

  .cornerMarkBox {
    z-index: 8;
    position: absolute;
    width: 180px;
    display: flex;
    bottom: 64px;
    right: 66px;
    cursor: pointer;
    justify-content: flex-end;

    .cornerMark {
      width: 36px;
      height: 36px;
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      margin-left: 10px;

      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.45);
    }

    .ckcss {
      background: rgba(65, 47, 27, 0.8);
      font-weight: bold;
      font-size: 18px;
    }
  }

  ::v-deep .el-carousel__indicators--horizontal {
    display: none !important;
  }

  ::v-deep .el-carousel__container {
    height: 800px;
  }
}

.introduction_about {
  width: 100%;
  height: 560px;
  background-image: url("../../../static/newImg2/contactUs_bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .introduction_about_box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .introduction_about_name {
    font-weight: bold;
    font-size: 88px;
    color: #ffffff;
    letter-spacing: 18px;
    text-align: center;
    letter-spacing: 1px;
    line-height: 80px;
    font-family: 楷体;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.middle {
  // background: url("../../../static/v1.0/编组 20.png");
  position: relative;
  min-width: 1900px;
  height: 1650px;
  margin: 0 auto;
  background-size: cover;
  background-position-y: -250px;

  // .imgbox {
  //   background: url("../../../static/v1.0/版块bg.png");
  //   width: 100%;
  //   min-height: 1750px;
  //   max-height: 1900px;
  //   position: absolute;
  //   bottom: -160px;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   z-index: -100;
  //   background-position-y: -100px;
  // }
  //   background-repeat: repeat-x;
  .titile {
    height: 350px;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 166px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .middle_new {
    display: flex;
    margin: 0 166px;
    justify-content: space-between;

    .middle_Left {
      .mask {
        min-width: 896px;
        height: 250px;
        bottom: 0;
        z-index: 10;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }

      cursor: pointer;
      border: 4px solid #bdb1a3;
      min-width: 896px;
      height: 512.4px;
      position: relative;

      .cornerMarkBoxMid {
        right: 30px;
        bottom: 24px;
        z-index: 8;
        position: absolute;
        display: flex;

        .cornerMarkMid {
          cursor: pointer;
          width: 36px;
          height: 36px;
          font-weight: 500;
          line-height: 36px;
          text-align: center;
          font-size: 18px;
          // color: #fff;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.7);
          // opacity: 0.7;
          margin: 0 4px;
        }

        .ckcss {
          background: rgba(65, 47, 27, 0.8);

          // background: #412f1b;
          font-weight: bold;
        }
      }

      .middle_item {
        min-width: 896px;
        height: 512.4px;

        .middle_font {
          font-family: kaiti;
          z-index: 11;
          position: absolute;
          bottom: 24px;
          margin-left: 29px;
          width: 638px;
          overflow: hidden;
          font-weight: 900;
          font-size: 28px;
          color: #ffffff;
          line-height: 42px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .middle_Rigth {
      .middle_Ritem:hover {
        color: #157b2f !important;
      }

      overflow: hidden;
      height: 512.4px;

      .middle_Ritem {
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        margin-left: 50px;
        margin-top: 50px;
      }

      .middle_Rfont {
        width: 580px;
        margin-bottom: 19px;
        overflow: hidden;
        font-family: kaiti;
        font-weight: 900;
        font-size: 28px;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .minddle_time {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }

      .minddle_danwie {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        width: 359px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__indicators {
      display: none !important;
    }

    ::v-deep .el-carousel__container {
      height: 512.4px;
    }
  }

  .middle_three {
    display: flex;
    margin: 0 166px;
    // width: 1900px;
    margin-top: 144px;
    justify-content: space-between;

    .home_module_8_content_right {
      margin: 50px 0;

      .home_module_8_content_dom {
        display: flex;
        font-size: 24px;
        font-family: kaiti;
        align-items: center;
        justify-content: space-between;
      }

      .home_module_8_content_right_title {
        font-weight: bold;
        font-size: 35px;
        color: #000000;
        line-height: 42px;
      }

      .home_module_8_content_right_date {
        margin: 40px 0 100px 0;
      }

      .home_module_8_content_right_more {
        font-weight: bold;
        font-size: 44px;
        color: #000000;
        letter-spacing: 2px;
        margin-top: 30px;
        position: relative;
        width: fit-content;
        cursor: pointer;
      }

      .home_module_8_content_right_more::after {
        position: absolute;
        content: " ";
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #2e2e2e;
      }

      .icon-left,
      .icon-right {
        border: 1px solid #1d1b19;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }

      .icon-left {
        margin-right: 20px;
      }

      .nodata {
        color: #cbcbcb;
        border: 1px solid #cbcbcb;
      }
    }

    .home_module_8_module {
      display: flex;

      .home_module_8_module_item {
        flex: 1 1 33%;
        margin-right: 50px;
        cursor: pointer;

        .home_module_8_cover {
          width: 100%;
          height: 275px;
          overflow: hidden;

          img {
            width: 100%;
            transition: all 0.2s;
            transform: scale(1.8);
          }
        }

        .home_module_8_date {
          width: 50%;
          margin-top: 24px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .home_module_8_title {
          width: 100%;
          margin-top: 20px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            font-family: kaiti;
            width: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .home_module_8_more1 {
          margin-top: 20px;

          .one {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0s infinite;
          }

          .two {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.4s infinite;
          }

          .three {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.8s infinite;
          }

          @keyframes fadeInFadeOut {
            0%,
            100% {
              opacity: 0;
            }

            /* 动画开始和结束时都是透明的 */
            50% {
              opacity: 1;
            }

            /* 动画中间是不透明的 */
          }

          .home_module_8_more1::after {
            content: "";
            display: block;
            width: 28px;
            height: 12px;
            background-image: url("../../../static/v1.0/L_b.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 34px;
          }
        }
      }

      .home_module_8_module_item:last-child {
        margin-right: 0;
      }

      .home_module_8_module_item:hover {
        img {
          transform: scale(1.9);
        }

        .home_module_8_date {
          color: #157b2f;
        }

        .home_module_8_title {
          color: #157b2f;
        }

        .home_module_8_more::after {
          width: 46px;
          background-image: url("../../../static/newImg2/more.png");
        }
      }
    }

    .middle_dom {
      width: 510px;

      .middle_img {
        width: 510px;
        height: 292px;
        background: skyblue;
      }

      .middle_time {
        margin-top: 32px;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        color: #211f1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }

      .middle_data {
        height: 84px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 900;
        font-size: 28px;
        color: #000000;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__container {
      height: 800px;
      width: 1568px;
    }
  }
}

.specific {
  margin: 0 auto;

  .specific_dom {
    margin: 0 160px;
    // margin-top: 90px;

    .font_left {
      .title {
        width: 800px;
        font-family: 楷体;
        font-weight: 900;
        font-size: 50px;
        color: #000000;
        // line-height: 42px;
        letter-spacing: 2px;
        font-style: normal;
        white-space: nowrap;
      }

      .font_ {
        width: 740px;
        margin-top: 40px;
        margin-bottom: 80px;
        font-weight: 400;
        font-size: 26px;
        color: #000;
        line-height: 59px;
        text-align: left;
        font-style: normal;
      }

      .font_top {
        width: 522px;
        display: flex;
        justify-content: space-between;

        .number {
          font-weight: bold;
          font-size: 60px;
          color: #1e9409;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }

        .numberTpye {
          font-weight: 400;
          font-size: 21px;
          color: #202020;
          line-height: 33px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
        }
      }

      .font_bottom {
        margin-top: 60px;
        width: 522px;
        display: flex;
        justify-content: space-between;

        .number {
          font-weight: bold;
          font-size: 60px;
          color: #1e9409;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }

        .numberTpye {
          font-weight: 400;
          font-size: 21px;
          color: #202020;
          line-height: 33px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
        }
      }
    }

    .right_img {
      // width: 820px;
      // height: 840px;
      // background: url("../../../static/v1.0/绍兴map.jpg");
      position: relative;
      margin-left: 25px;
      width: 728px;
      height: 840px;
      background: url("../../../static/v1.0/绍兴map.jpg");

      .dot1 {
        display: flex;
        align-items: center;
        position: absolute;
        right: 50px;
        top: 40%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 12px;
          background: #1b7c2b;
        }

        .home_map_right_introduction_flicker {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 2%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: #136a2c;
          opacity: 0.2;
          animation: bigPointer 2s ease-out both;
          animation-iteration-count: infinite;
          transform-origin: 0 0;
        }

        .right_dot {
          width: 215px;
          font-size: 14px;
          height: 46px;
          text-align: center;
          line-height: 46px;
          background: #f8fff6;
          border-radius: 9px;
          border: 1px solid #45853b;
        }
      }

      .dot2 {
        display: flex;
        align-items: center;
        position: absolute;
        right: -35px;
        top: 54%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 12px;
          background: #1b7c2b;
        }

        .home_map_right_introduction_flicker {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 2%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: #136a2c;
          opacity: 0.2;
          animation: bigPointer 2s ease-out both;
          animation-iteration-count: infinite;
          transform-origin: 0 0;
        }

        .right_dot1 {
          width: 215px;
          height: 46px;
          text-align: center;
          line-height: 46px;
          background: #f8fff6;
          border-radius: 9px;
          border: 1px solid #45853b;
        }
      }
    }
  }

  .specific_child {
    background: url("../../../static/v1.0/青山.png") center bottom / cover
      no-repeat;

    .specific_child_info_project {
      padding: 70px;
      width: 1600px;

      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      background-size: cover;
      background-repeat: no-repeat;

      .specific_child_info_project_item {
        cursor: pointer;
        // width: 49%;
        margin-right: 25px;
        margin-top: 30px;

        .specific_child_info_project_item_img {
          position: relative;
          max-width: 750px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          .big_img {
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.8) 100%
            ) !important;
            height: 100% !important;
          }

          .specific_child_title {
            height: 125px;
            line-height: 125px;
            width: 100%;
            background: linear-gradient(
              to bottom,
              rgba(13, 117, 55, 0) 0%,
              #076504 100%
            );
            bottom: 0;
            font-family: 楷体;
            position: absolute;
            font-weight: 900;
            font-size: 36px;
            color: #ffffff;
            line-height: 42px;
            letter-spacing: 1px;
          }

          width: 100%;
          height: 362px;
          overflow: hidden;

          img {
            display: block;
            border-radius: 5px;
            transition: all 1s;
          }
        }

        .specific_child_info_project_item_tips {
          // padding: ;
          height: 129px;
          padding: 20px 44px 20px 32px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: 0px 2px 23px 0px rgba(21, 123, 47, 0.13);
          //   padding: 24px;
          max-width: 674px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
          position: relative;

          .specific_child_info_project_item_tips_left {
            font-size: 24px;
            font-weight: 400;
            color: #000;
            line-height: 40px;
          }

          .specific_child_info_project_item_tips_right {
            height: 81px;
            font-size: 20px;
            color: #000;
            line-height: 27px;
            font-weight: 400;
            margin-bottom: 25px;
            // height: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            // 只要超过宽度就换行，不论中文还是英文
            word-break: break-all;
            //最多展示两行
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .specific_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #157b2f;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }

          .specific_child_info_project_item_tips_line {
            width: 0;
            height: 2px;
            background-color: #1e9409;
            transition: all 1s;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .specific_child_info_project_item:hover {
        .specific_child_info_project_item_tips_line {
          width: 100%;
        }
      }
    }
  }

  .specific_field {
    position: relative;
    margin-top: 85px;
    padding: 0 160px;
    background: url("../../../static/v1.0/青山.png");

    .field_dom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item_fieDom:hover {
        .item_Dom {
          transform: translateY(0);
          transition: all 0.5s;
        }

        .item_title {
          margin-bottom: 25px !important;
        }
      }

      .item_fieDom {
        cursor: pointer;
        box-shadow: 0px 2px 11px 0 (53, 74, 50, 0.1);
        position: relative;
        height: 500px;
        border-radius: 10px;
        overflow: hidden;
        background: #5c7a63;
        flex: 0 48%;

        /* 每个项目占据一行宽度的1/3 */
        .item_Dom {
          position: absolute;
          height: 340px;
          bottom: 0;
          transform: translateY(140px);
          background: linear-gradient(
            180deg,
            rgba(18, 20, 19, 0) 0%,
            #1b201b 100%
          );
          box-shadow: 0px 2px 31px 0px rgba(21, 123, 47, 0.18);

          .item_title {
            margin-top: 98px;
            margin-bottom: 55px;
            font-family: 楷体;
            font-weight: 900;
            font-size: 44px;
            color: #ffffff;
            line-height: 42px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
            text-align: center;
          }

          .item_cent {
            height: 93px;
            font-weight: 400;
            margin: 0 80px;
            font-size: 16px;
            color: #ffffff;
            line-height: 31px;
            text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
            text-align: left;
            font-style: normal;
          }

          .jiaobioa {
            text-align: center;
          }
        }
      }

      .item_fieDom:nth-child(1) {
        margin-right: 40px;
        margin-bottom: 40px;
      }
    }
  }
}

.base {
  position: relative;
  min-width: 1900px;
  margin: 0 auto;
  background: url("../../../static/v1.0/山.png");
  background-repeat: no-repeat;
  background-size: cover;

  .base_bul {
    margin: 0 160px;

    .titile {
      height: 350px;
      margin: 0 auto;

      .oneI {
        position: absolute;
        top: 210px;
        left: 50%;
        transform: translateX(-50%);
      }

      .twoI {
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img {
        width: 350px;
        height: 58px;
        position: absolute;
        top: 225px;
        left: 50%;
        transform: translateX(-50%);
      }

      .imgT {
        position: absolute;
        top: 310px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 21px;
        color: #6d6d6d;
        line-height: 32px;
        letter-spacing: 25px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .base_dom {
      display: flex;

      justify-content: center;

      .onbaseClass {
        background: #0b5b20 !important;
        border-radius: 6px;
        color: #ffffff !important;
      }

      .base_all {
        width: 200px;
        height: 82px;
        line-height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        .base_font {
          font-family: 楷体;
          font-weight: 400;
          font-size: 28px;

          line-height: 42px;
          letter-spacing: 2px;
          text-align: left;
        }
      }
    }

    .base_dom :nth-child(n) {
      // margin-left: 33px;
    }

    .base_dom :nth-child(1) {
      margin-left: 0px;
    }

    .base_child {
      width: 1550px;
      margin: 0 auto;

      .base_child_info_project {
        margin-top: 70px;
        padding-bottom: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .base_child_info_project_item {
          cursor: pointer;
          margin-right: 25px;
          // width: 49%;
          margin-top: 30px;

          .base_child_info_project_item_img {
            position: relative;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            width: 750px;
            height: 362px;

            .base_child_title {
              height: 125px;
              line-height: 125px;
              width: 100%;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.03) 0%,
                rgba(0, 0, 0, 0.83) 100%
              );
              bottom: 0;
              font-family: 楷体;
              position: absolute;
              font-weight: 900;
              font-size: 36px;
              color: #ffffff;
              line-height: 42px;
              letter-spacing: 1px;
              // text-align: center;
            }

            overflow: hidden;

            img {
              display: block;
              border-radius: 5px;
              transition: all 1s;
            }
          }

          .base_child_info_project_item_tips {
            //  border-top-right-radius: 10px;
            // border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 24px;
            max-width: 702px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
            position: relative;

            .base_child_info_project_item_tips_left {
              font-size: 24px;
              font-weight: 400;
              color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              color: #000;
              line-height: 27px;
              font-weight: 400;
              height: 55px;
              overflow: hidden;
              text-overflow: ellipsis;
              // 只要超过宽度就换行，不论中文还是英文
              word-break: break-all;
              //最多展示两行
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              // color: #595959;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }

            .base_child_info_project_item_tips_line {
              width: 0;
              height: 2px;
              background-color: #1e9409;
              transition: all 1s;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }

        .base_child_info_project_item:hover {
          .base_child_info_project_item_img img {
            transform: scale(1.1);
          }

          .base_child_info_project_item_tips_line {
            width: 100%;
          }
        }

        .base_child_info_project_item_only {
          display: flex;
          margin-top: 20px;
          cursor: pointer;

          .base_child_info_project_item_img {
            width: 750px;
            height: 362px;
            overflow: hidden;

            img {
              display: block;
            }
          }

          .base_child_info_project_item_tips {
            width: calc(100% - 590px - 48px);
            padding: 24px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

            .base_child_info_project_item_tips_left {
              font-size: 20px;
              color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              margin-top: 12px;
              font-size: 16px;
              font-weight: 400;
              color: #000;
              line-height: 27px;
              height: 85px;
              overflow: hidden;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }
          }
        }
      }
    }
  }
}

// .product {
//   background: url("../../../static/v1.0/花.png");
//   width: 1900px;
//   margin: 0 auto;
//   .product_tit {
//     margin: 0 160px;
//     .titile {
//       height: 350px;
//       position: relative;
//       margin: 0 auto;
//       .oneI {
//         position: absolute;
//         top: 150px;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//       .twoI {
//         position: absolute;
//         top: 176px;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//       .img {
//         width: 350px;
//         height: 58px;
//         position: absolute;
//         top: 166px;
//         left: 50%;
//         transform: translateX(-50%);
//       }
//       .imgT {
//         position: absolute;
//         top: 243px;
//         left: 50%;
//         transform: translateX(-50%);
//         font-weight: 400;
//         font-size: 21px;
//         // color: #6d6d6d;
//         line-height: 32px;
//         letter-spacing: 25px;
//         text-align: center;
//         font-style: normal;
//         text-transform: uppercase;
//       }
//     }
//     .product_font {
//       margin-top: 50px;
//       padding-bottom: 50px;
//       font-family: 楷体;
//       font-weight: 400;
//       font-size: 20px;
//       color: #060606;
//       line-height: 42px;
//       text-align: center;
//       font-style: normal;
//     }
//     .product_list {
//       display: flex;
//       flex-wrap: wrap;
//       .product_dom {
//         position: relative;
//         background: pink;
//         margin-bottom: 40px;
//         width: 512px;
//         height: 384px;
//         flex: 0 0 31.9%; /* 每个项目占据一行宽度的1/3 */
//         .product_dom_font {
//           position: absolute;
//           bottom: 40px;
//           left: 38px;
//         }
//       }
//       .product_dom:nth-child(2) {
//         margin: 0 32px;
//         background: #157b2f;
//       }
//       .product_dom:nth-child(5) {
//         margin: 0 32px;
//         background: red;
//       }
//     }
//     .more {
//       margin-top: 85px;
//       cursor: pointer;
//       //   padding-bottom: 85px;
//       font-weight: 400;
//       font-size: 36px;
//       color: #060606;
//       line-height: 42px;
//       text-align: center;
//       font-style: normal;
//       text-decoration-line: underline;
//     }
//   }
// }
.font {
  height: 101px;
  border-top: 1px solid #e6e6e6;
  width: 1568px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: flex;

  .font_dom {
    z-index: 10;
    // width: 200px;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    // margin-right: 25px;
  }

  .font_for {
    display: flex;
  }
}

.allBranch {
  width: 1050px;
  // width: 1076px;
  overflow: hidden;
  position: absolute;
  /* height: 800px; */
  right: 0px;
  top: -100px;
}
</style>
